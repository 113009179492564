import { useState } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export const ThemeContextProvider = ({ children, config }) => {
  const [themeConfig, setThemeConfig] = useState(config);

  return (
    <ThemeContext.Provider value={{ themeConfig: themeConfig, setThemeConfig }}>
      {children}
    </ThemeContext.Provider>
  );
};
