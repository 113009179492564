import AuthLayout from '../auth-layout/AuthLayout';

const Register = ({
  onBackPressed,
  onActionClick,
  onSubmit,
  onGoogleActionClick,
  onFacebookActionClick
}) => {
  return (
    <AuthLayout
      title="Register"
      description="Register now and start enjoying delicious food delivered right to your door"
      submitText="REGISTER"
      onBackPressed={onBackPressed}
      onActionClick={onActionClick}
      actionText="Sign In"
      actionDescription="Already have an account?"
      onSubmit={onSubmit}
      onGoogleActionClick={onGoogleActionClick}
      onFacebookActionClick={onFacebookActionClick}
    />
  );
};

export default Register;
