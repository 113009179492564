import React from 'react';

import './carousel.scss';
import { CSSTransition } from 'react-transition-group';

const Carousel = ({ children, index }) => (
  <div className="carousel">
    <div className="carousel__slide-container">
      {children.map((child, childIndex) => (
        <CSSTransition
          key={childIndex}
          classNames="carousel__slide"
          timeout={800}
          in={index === childIndex}
          unmountOnExit
        >
          <div className="carousel__slide-item">{child}</div>
        </CSSTransition>
      ))}
    </div>
  </div>
);

export default Carousel;
