import './input.scss';
import { useTheme } from '../../../hooks/useTheme';

const Input = ({ label, type, name, value, placeholder, onChange, required, Icon }) => {
  const { themeConfig } = useTheme();
  const backgroundColor = themeConfig?.secondary?.background;
  const textColor = themeConfig?.primary?.text;

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    onChange({ name, value });
  };

  return (
    <div className="form-input" style={{ background: backgroundColor, color: textColor }}>
      <label htmlFor={name}>{label}</label>
      {Icon && <Icon width={20} height={20} style={{ color: themeConfig?.primary?.icon }} />}
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        required={required}
      />
    </div>
  );
};

export default Input;
