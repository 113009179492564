import AuthLayout from '../auth-layout/AuthLayout';

const SignIn = ({
  onBackPressed,
  onActionClick,
  onSubmit,
  onGoogleActionClick,
  onFacebookActionClick,
  onForgotPasswordClick
}) => {
  return (
    <AuthLayout
      title="Sign In"
      description="Welcome back! Sign in to access your account and enjoy a seamless food delivery experience"
      submitText="SIGN IN"
      onBackPressed={onBackPressed}
      onActionClick={onActionClick}
      actionText="Register"
      actionDescription="Don’t have an account?"
      onSubmit={onSubmit}
      showForgotPassword
      onGoogleActionClick={onGoogleActionClick}
      onFacebookActionClick={onFacebookActionClick}
      onForgotPasswordClick={onForgotPasswordClick}
    />
  );
};

export default SignIn;
