const Form = ({ onSubmit, data, children, className }) => {
  const handleOnSubmit = (event) => {
    event.preventDefault();
    onSubmit(data);
  };

  return (
    <form onSubmit={handleOnSubmit} className={className}>
      {children}
    </form>
  );
};

export default Form;
