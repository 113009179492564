export const THEME_DARK = {
  primary: {
    DEFAULT: '#E53935',
    background: '#212121',
    text: '#FFFFFF',
    icon: '#FFFFFF'
  },
  secondary: {
    background: '#424242'
  },
  fire: {
    background: '#212121',
    baseColour1: '#C62828',
    baseColour2: '#EF6C00'
  }
};
