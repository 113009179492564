import { useEffect, useRef } from 'react';
import { DEFAULT_SETTINGS, FIRE_FRAGMENT_SHADER, VERTEX_SHADER } from './utils';

import './fire-animation-background.scss';

const FireAnimationBackground = ({ children, opacity = 1, isDark = true }) => {
  const containerRef = useRef();
  const canvasRef = useRef();
  const requestId = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const canvas = canvasRef.current;
    const contextAttributes = { antialias: true };
    const webgl =
      canvas.getContext('webgl', contextAttributes) ||
      canvas.getContext('experimental-webgl', contextAttributes);

    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;

    const settings = DEFAULT_SETTINGS;

    let stop = false;
    let startTime = undefined;

    //Get attributes of container and add to settings.
    getDataAttributes(settings, container);

    if (!webgl) {
      log('Unable to initialize WebGL.');
      return () => {};
    }

    const devicePixelRatio = window.devicePixelRatio || 1;

    const width = canvas.clientWidth * devicePixelRatio;
    const height = canvas.clientHeight * devicePixelRatio;

    // Adjust canvas size to match pixel density
    canvas.width = width;
    canvas.height = height;

    // Set the viewport to match the canvas size
    webgl.viewport(0, 0, width, height);

    //Adjust fire shape/strength based on width
    settings.fireShape = Math.round(width / 200);
    settings.fireStrength = Math.round(width / 7);

    //Create shaders
    const vertexShader = createShader(settings, webgl, webgl.VERTEX_SHADER, VERTEX_SHADER);
    const fragmentShader = createShader(
      settings,
      webgl,
      webgl.FRAGMENT_SHADER,
      FIRE_FRAGMENT_SHADER
    );

    //Create shader program
    const program = createProgram(settings, webgl, [vertexShader, fragmentShader]);
    webgl.useProgram(program);

    // init position attribute: 3 dots by (x, y)
    const aPosition = new Float32Array([-1, -1, 1, 1, 1, -1, -1, -1, 1, 1, -1, 1]);
    createShaderAttribute(settings, webgl, program, 'aPosition', 2, 0, 0, aPosition);

    //Init Resolution uniform
    const uResolution = webgl.getUniformLocation(program, 'uResolution');
    webgl.uniform2fv(
      uResolution,
      new Float32Array([webgl.drawingBufferWidth, webgl.drawingBufferHeight])
    );

    //Init Theme uniform
    const uDark = webgl.getUniformLocation(program, 'uDark');
    webgl.uniform1i(uDark, isDark ? 1 : 0);

    const uTime = webgl.getUniformLocation(program, 'uTime');

    const render = (timestamp) => {
      if (timestamp === undefined) return;

      if (!stop) {
        // the first call
        if (startTime === undefined) {
          startTime = timestamp;
        }

        const uTimeValue = (timestamp - startTime) / 1000;

        webgl.clear(webgl.COLOR_BUFFER_BIT);
        webgl.uniform1f(uTime, timestamp / 1000);
        webgl.drawArrays(webgl.TRIANGLES, 0, 6);
        // if the time > 10 min -> reset the timer
        if (uTimeValue > 10 * 60) {
          startTime = undefined;
        }
        requestId.current = requestAnimationFrame(render);
      }
    };

    const resizeObserver = handleResize(container, canvas, render, webgl, uResolution);

    requestId.current = requestAnimationFrame(render);

    return () => {
      cancelAnimationFrame(requestId.current);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [isDark]);

  return (
    <section ref={containerRef} className="fire" data-background-color="#FFFFFF">
      <canvas
        ref={canvasRef}
        className="jso-nature-canvas fire__canvas"
        data-jso-nature-canvas=""
        style={{ opacity: opacity }}
      />
      <div data-content="" className="fire__content">
        {children}
      </div>
    </section>
  );
};

const handleResize = (container, canvas, render, webgl, uResolution) => {
  const supported = 'ResizeObserver' in window;

  if (supported) {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
        const entry = entries_1[_i];
        canvas.width = entry.contentRect.width;
        canvas.height = entry.contentRect.height;
        // define webgl viewport
        webgl.viewport(0, 0, entry.contentRect.width, entry.contentRect.height);
        // update uResolution that is passed to the fragment shader
        webgl.uniform2fv(
          uResolution,
          new Float32Array([webgl.drawingBufferWidth, webgl.drawingBufferHeight])
        );
      }
      render();
    });
    resizeObserver.observe(container);
    return resizeObserver;
  }
  return null;
};

const getDataAttributes = (settings, container) => {
  settings.fireBackgroundColor =
    container.getAttribute('data-background-color') || settings.fireBackgroundColor;
  settings.fireBaseColor1 = container.getAttribute('data-base-color-1') || settings.fireBaseColor1;
  settings.fireBaseColor2 = container.getAttribute('data-base-color-2') || settings.fireBaseColor2;
  settings.fireShape = Number(container.getAttribute('data-fire-shape')) || settings.fireShape;
  settings.fireSpeed = Number(container.getAttribute('data-fire-speed')) || settings.fireSpeed;
  settings.fireStrength =
    Number(container.getAttribute('data-fire-strength')) || settings.fireStrength;
  settings.fireDetalization =
    Number(container.getAttribute('data-fire-detalization')) || settings.fireDetalization;
};

const createShader = (settings, webgl, shaderType, shaderCode) => {
  const shader = webgl.createShader(shaderType);
  webgl.shaderSource(shader, shaderCode);
  webgl.compileShader(shader);

  if (!webgl.getShaderParameter(shader, webgl.COMPILE_STATUS) && settings.debug) {
    log('Error compiling shader: ' + webgl.getShaderInfoLog(shader));
    return null;
  }
  return shader;
};

const createProgram = (settings, webgl, shaders) => {
  const program = webgl.createProgram();

  for (let i = 0; i < shaders.length; i++) {
    webgl.attachShader(program, shaders[i]);
  }

  webgl.linkProgram(program);
  if (!webgl.getProgramParameter(program, webgl.LINK_STATUS) && settings.debug) {
    log('Error linking program: ' + webgl.getProgramInfoLog(program));
    return null;
  }

  // Log status
  if (settings.debug) {
    webgl.validateProgram(program);
    if (!webgl.getProgramParameter(program, webgl.VALIDATE_STATUS)) {
      log('Error validation program: ' + webgl.getProgramInfoLog(program));
      return null;
    }
  }
  return program;
};

const createShaderAttribute = (
  settings,
  webgl,
  program,
  attrName,
  elementsNum,
  vertexSize,
  offset,
  data
) => {
  const vertexBuffer = webgl.createBuffer();
  webgl.bindBuffer(webgl.ARRAY_BUFFER, vertexBuffer);
  if (data) {
    webgl.bufferData(webgl.ARRAY_BUFFER, data, webgl.STATIC_DRAW);
  }
  const attrLocation = webgl.getAttribLocation(program, attrName);
  webgl.vertexAttribPointer(
    attrLocation, // attribute location
    elementsNum, // number of elements per attribute (x, y) or (x, y, z) etc.
    webgl.FLOAT, // 32 bit floats
    false,
    vertexSize * Float32Array.BYTES_PER_ELEMENT, // size of an individual vertex
    offset * Float32Array.BYTES_PER_ELEMENT // offset from the beginning of a single vertex to this attribute
  );
  webgl.enableVertexAttribArray(attrLocation);
  return attrLocation;
};

const log = (msg) => {
  /* eslint-disable no-console */
  console.log('Fire Animation WebGL: ' + msg);
};

export default FireAnimationBackground;
