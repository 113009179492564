import './login.scss';
import { useTheme } from '../../hooks/useTheme';

import FireAnimationBackground from '../fire-animation-background/FireAnimationBackground';
import LoginOptions from './login-options/LoginOptions';
import Carousel from '../common/carousel/Carousel';
import { useState } from 'react';
import SignIn from './sign-in/SignIn';
import Register from './register/Register';

const Login = () => {
  const { themeConfig } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLoginOptionsChange = () => setCurrentIndex(0);
  const handleSignInChange = () => setCurrentIndex(1);
  const handleRegisterChange = () => setCurrentIndex(2);

  const validateEmail = (email) => {
    if (!email) {
      //TODO Show dialog to request email to be entered
      /* eslint-disable no-console */
      console.log('Error: ', 'Invalid Input', 'Please provide a valid email!');
    }

    return email;
  };

  const handleSubmit = (formData) => {
    /* eslint-disable no-console */
    console.log(formData);
  };

  const handleForgotPassword = (email) => {
    if (validateEmail(email)) {
      /* eslint-disable no-console */
      console.log('Forgot Password: ', email);
    }
  };

  const handleGoogleAuth = () => {
    /* eslint-disable no-console */
    console.log('Google');
  };

  const handleFacebookAuth = () => {
    /* eslint-disable no-console */
    console.log('Facebook');
  };

  return (
    <section className="login">
      <FireAnimationBackground>
        <div className="login__overlay" style={{ background: themeConfig?.primary?.background }} />
        <div className="login__content">
          <Carousel index={currentIndex}>
            <LoginOptions
              onSignInClick={handleSignInChange}
              onRegisterClick={handleRegisterChange}
              onGoogleActionClick={handleGoogleAuth}
              onFacebookActionClick={handleFacebookAuth}
            />
            <SignIn
              onActionClick={handleRegisterChange}
              onBackPressed={handleLoginOptionsChange}
              onSubmit={handleSubmit}
              onForgotPasswordClick={handleForgotPassword}
              onGoogleActionClick={handleGoogleAuth}
              onFacebookActionClick={handleFacebookAuth}
            />
            <Register
              onActionClick={handleSignInChange}
              onBackPressed={handleLoginOptionsChange}
              onSubmit={handleSubmit}
              onGoogleActionClick={handleGoogleAuth}
              onFacebookActionClick={handleFacebookAuth}
            />
          </Carousel>
        </div>
      </FireAnimationBackground>
    </section>
  );
};

export default Login;
