import './button.scss';
import { useTheme } from '../../../hooks/useTheme';

const Button = ({ outline, children, className, onClick, type }) => {
  const { themeConfig } = useTheme();

  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        background: `${outline ? 'transparent' : themeConfig?.primary?.DEFAULT}`,
        border: `1px solid ${themeConfig?.primary?.DEFAULT}`
      }}
      className={`button ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
