import Button from '../../common/button/Button';

import { ReactComponent as Google } from '../../../icons/google.svg';
import { ReactComponent as Facebook } from '../../../icons/facebook.svg';
import { ReactComponent as Back } from '../../../icons/back.svg';
import { ReactComponent as User } from '../../../icons/user.svg';
import { ReactComponent as Password } from '../../../icons/password.svg';
import { useTheme } from '../../../hooks/useTheme';

import { useState } from 'react';
import Form from '../../common/form/Form';
import Input from '../../common/input/Input';

import './auth-layout.scss';

const AuthLayout = ({
  title,
  description,
  submitText,
  actionText,
  actionDescription,
  showForgotPassword,
  onBackPressed,
  onSubmit,
  onActionClick,
  onGoogleActionClick,
  onFacebookActionClick,
  onForgotPasswordClick
}) => {
  const { themeConfig } = useTheme();
  const textColor = themeConfig?.primary?.text;
  const [formData, setFormData] = useState({});

  const handleOnSubmit = () => {
    onSubmit(formData);
  };

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="auth-layout">
      <div className="auth-layout__back" onClick={onBackPressed}>
        <Back width={24} height={24} style={{ color: themeConfig?.primary?.icon }} />
      </div>
      <div className="auth-layout__title">
        <span>{title}</span>
        <span>{description}</span>
      </div>
      <div className="auth-layout__content">
        <Form onSubmit={handleOnSubmit} data={formData} className="auth-layout__form">
          <Input
            type="text"
            name="username"
            placeholder="Username"
            required
            value={formData?.username}
            onChange={handleOnInputChange}
            Icon={User}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            required
            value={formData?.password}
            onChange={handleOnInputChange}
            Icon={Password}
          />
          <Button type="submit">{submitText}</Button>
        </Form>
        {showForgotPassword && (
          <span
            style={{ color: textColor }}
            className="auth-layout__forgot"
            onClick={() => onForgotPasswordClick(formData?.username)}
          >
            Forgot Password
          </span>
        )}
        <div
          className="auth-layout__action"
          style={{ marginTop: showForgotPassword ? '0' : '24px' }}
        >
          <span style={{ color: textColor }}>{actionDescription}</span>
          <span style={{ color: themeConfig?.primary?.DEFAULT }} onClick={onActionClick}>
            {actionText}
          </span>
        </div>
        <div className="auth-layout__connect-with">
          <div style={{ background: themeConfig?.secondary?.background }} />
          <span style={{ color: textColor }}> OR CONNECT WITH</span>
          <div style={{ background: themeConfig?.secondary?.background }} />
        </div>
        <div className="auth-layout__firebase">
          <Google width={48} height={48} onClick={onGoogleActionClick} />
          <Facebook
            width={48}
            height={48}
            onClick={onFacebookActionClick}
          />
        </div>
        <div className="auth-layout__footer">
          <span style={{ color: textColor }}>Terms &amp; Conditions &amp; Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
