export const DEFAULT_SETTINGS = {
  debug: true,
  fireBackgroundColor: '#000000',
  fireBaseColor1: '#bc4c18',
  fireBaseColor2: '#fff200',
  fireShape: 7,
  fireSpeed: 1.0,
  fireStrength: 175,
  fireDetalization: 2
};

export const VERTEX_SHADER =
  'attribute vec2 aPosition;\n\nvoid main(void){\n    gl_Position = vec4(aPosition, 0.0, 1.0);\n}';

export const FIRE_FRAGMENT_SHADER =
  '#ifdef GL_ES\n' +
  'precision highp float;\n' +
  '#endif\n' +
  '\n' +
  '#define PI 3.1415927\n' +
  '#define TWO_PI 6.283185\n' +
  '\n' +
  '#define ANIMATION_SPEED 1.5\n' +
  '#define MOVEMENT_SPEED 1.0\n' +
  '#define MOVEMENT_DIRECTION vec2(0.7, -1.0)\n' +
  '\n' +
  '#define PARTICLE_SIZE 0.009\n' +
  '\n' +
  '#define PARTICLE_SCALE (vec2(0.5, 1.6))\n' +
  '#define PARTICLE_SCALE_VAR (vec2(0.25, 0.2))\n' +
  '\n' +
  '#define PARTICLE_BLOOM_SCALE (vec2(0.5, 0.8))\n' +
  '#define PARTICLE_BLOOM_SCALE_VAR (vec2(0.3, 0.1))\n' +
  '\n' +
  '#define SPARK_COLOR vec3(1.0, 0.4, 0.05) * 1.5\n' +
  '#define BLOOM_COLOR vec3(1.0, 0.4, 0.05) * 0.8\n' +
  '#define SMOKE_COLOR vec3(1.0, 0.43, 0.1) * 0.8\n' +
  '\n' +
  '#define SIZE_MOD 1.5\n' +
  '#define ALPHA_MOD 0.6\n' +
  '#define LAYERS_COUNT 15\n' +
  '\n' +
  'uniform float uTime;\n' +
  'uniform vec2 uResolution;\n' +
  'uniform bool uDark;\n' +
  '\n' +
  'vec3 getThemeColor(in vec3 color) {\n' +
  '    return uDark ? color : 1.0 - color;\n' +
  '}\n' +
  '\n' +
  'vec3 getSmokeColor()\n' +
  '{\n' +
  '    return getThemeColor(SMOKE_COLOR);\n' +
  '}\n' +
  '\n' +
  'vec3 getSparkColor()\n' +
  '{\n' +
  '    return getThemeColor(SPARK_COLOR);\n' +
  '}\n' +
  '\n' +
  'vec3 getBloomColor() {\n' +
  '    return getThemeColor(BLOOM_COLOR);\n' +
  '}\n' +
  '\n' +
  'float hash1_2(in vec2 x)\n' +
  '{\n' +
  '    return fract(sin(dot(x, vec2(52.127, 61.2871))) * 521.582);\n' +
  '}\n' +
  '\n' +
  'vec2 hash2_2(in vec2 x)\n' +
  '{\n' +
  '    return fract(sin(x * mat2(20.52, 24.1994, 70.291, 80.171)) * 492.194);\n' +
  '}\n' +
  '\n' +
  '//Simple interpolated noise\n' +
  'vec2 noise2_2(vec2 uv)\n' +
  '{\n' +
  '    //vec2 f = fract(uv);\n' +
  '    vec2 f = smoothstep(0.0, 1.0, fract(uv));\n' +
  '\n' +
  '    vec2 uv00 = floor(uv);\n' +
  '    vec2 uv01 = uv00 + vec2(0,1);\n' +
  '    vec2 uv10 = uv00 + vec2(1,0);\n' +
  '    vec2 uv11 = uv00 + 1.0;\n' +
  '    vec2 v00 = hash2_2(uv00);\n' +
  '    vec2 v01 = hash2_2(uv01);\n' +
  '    vec2 v10 = hash2_2(uv10);\n' +
  '    vec2 v11 = hash2_2(uv11);\n' +
  '\n' +
  '    vec2 v0 = mix(v00, v01, f.y);\n' +
  '    vec2 v1 = mix(v10, v11, f.y);\n' +
  '    vec2 v = mix(v0, v1, f.x);\n' +
  '\n' +
  '    return v;\n' +
  '}\n' +
  '\n' +
  '//Simple interpolated noise\n' +
  'float noise1_2(in vec2 uv)\n' +
  '{\n' +
  '    vec2 f = fract(uv);\n' +
  '    //vec2 f = smoothstep(0.0, 1.0, fract(uv));\n' +
  '\n' +
  '    vec2 uv00 = floor(uv);\n' +
  '    vec2 uv01 = uv00 + vec2(0,1);\n' +
  '    vec2 uv10 = uv00 + vec2(1,0);\n' +
  '    vec2 uv11 = uv00 + 1.0;\n' +
  '\n' +
  '    float v00 = hash1_2(uv00);\n' +
  '    float v01 = hash1_2(uv01);\n' +
  '    float v10 = hash1_2(uv10);\n' +
  '    float v11 = hash1_2(uv11);\n' +
  '\n' +
  '    float v0 = mix(v00, v01, f.y);\n' +
  '    float v1 = mix(v10, v11, f.y);\n' +
  '    float v = mix(v0, v1, f.x);\n' +
  '\n' +
  '    return v;\n' +
  '}\n' +
  '\n' +
  'float layeredNoise1_2(in vec2 uv, in float sizeMod, in float alphaMod, in int layers, in float animation)\n' +
  '{\n' +
  '    float noise = 0.0;\n' +
  '    float alpha = 1.0;\n' +
  '    float size = 1.0;\n' +
  '    vec2 offset;\n' +
  '    for (int i = 0; i < LAYERS_COUNT; i++)\n' +
  '    {\n' +
  '        offset += hash2_2(vec2(alpha, size)) * 10.0;\n' +
  '\n' +
  '        //Adding noise with movement\n' +
  '        noise += noise1_2(uv * size + uTime * animation * 8.0 * MOVEMENT_DIRECTION * MOVEMENT_SPEED + offset) * alpha;\n' +
  '        alpha *= alphaMod;\n' +
  '        size *= sizeMod;\n' +
  '    }\n' +
  '\n' +
  '    noise *= (1.0 - alphaMod)/(1.0 - pow(alphaMod, float(layers)));\n' +
  '    return noise;\n' +
  '}\n' +
  '\n' +
  '//Rotates point around 0,0\n' +
  'vec2 rotate(in vec2 point, in float deg)\n' +
  '{\n' +
  '    float s = sin(deg);\n' +
  '    float c = cos(deg);\n' +
  '    return mat2(s, c, -c, s) * point;\n' +
  '}\n' +
  '\n' +
  '//Cell center from point on the grid\n' +
  'vec2 voronoiPointFromRoot(in vec2 root, in float deg)\n' +
  '{\n' +
  '    vec2 point = hash2_2(root) - 0.5;\n' +
  '    float s = sin(deg);\n' +
  '    float c = cos(deg);\n' +
  '    point = mat2(s, c, -c, s) * point * 0.66;\n' +
  '    point += root + 0.5;\n' +
  '    return point;\n' +
  '}\n' +
  '\n' +
  '//Voronoi cell point rotation degrees\n' +
  'float degFromRootUV(in vec2 uv)\n' +
  '{\n' +
  '    return uTime * ANIMATION_SPEED * (hash1_2(uv) - 0.5) * 2.0;\n' +
  '}\n' +
  '\n' +
  'vec2 randomAround2_2(in vec2 point, in vec2 range, in vec2 uv)\n' +
  '{\n' +
  '    return point + (hash2_2(uv) - 0.5) * range;\n' +
  '}\n' +
  '\n' +
  '\n' +
  'vec3 fireParticles(in vec2 uv, in vec2 originalUV)\n' +
  '{\n' +
  '    vec3 particles = vec3(0.0);\n' +
  '    vec2 rootUV = floor(uv);\n' +
  '    float deg = degFromRootUV(rootUV);\n' +
  '    vec2 pointUV = voronoiPointFromRoot(rootUV, deg);\n' +
  '    float dist = 2.0;\n' +
  '    float distBloom = 0.0;\n' +
  '\n' +
  '    //UV manipulation for the faster particle movement\n' +
  '    vec2 tempUV = uv + (noise2_2(uv * 2.0) - 0.5) * 0.1;\n' +
  '    tempUV += -(noise2_2(uv * 3.0 + uTime) - 0.5) * 0.07;\n' +
  '\n' +
  '    //Sparks sdf\n' +
  '    dist = length(rotate(tempUV - pointUV, 0.7) * randomAround2_2(PARTICLE_SCALE, PARTICLE_SCALE_VAR, rootUV));\n' +
  '\n' +
  '    //Bloom sdf\n' +
  '    distBloom = length(rotate(tempUV - pointUV, 0.7) * randomAround2_2(PARTICLE_BLOOM_SCALE, PARTICLE_BLOOM_SCALE_VAR, rootUV));\n' +
  '\n' +
  '    //Add sparks\n' +
  '    particles += (1.0 - smoothstep(PARTICLE_SIZE * 0.6, PARTICLE_SIZE * 3.0, dist)) * getSparkColor();\n' +
  '\n' +
  '    //Add bloom\n' +
  '    particles += pow((1.0 - smoothstep(0.0, PARTICLE_SIZE * 6.0, distBloom)) * 1.0, 3.0) * getBloomColor();\n' +
  '\n' +
  '    //Upper disappear curve randomization\n' +
  '    float border = (hash1_2(rootUV) - 0.5) * 2.0;\n' +
  '    float disappear = 1.0 - smoothstep(border, border + 0.5, originalUV.y);\n' +
  '\n' +
  '    //Lower appear curve randomization\n' +
  '    border = (hash1_2(rootUV + 0.214) - 1.8) * 0.7;\n' +
  '    float appear = smoothstep(border, border + 0.4, originalUV.y);\n' +
  '\n' +
  '    return particles * disappear * appear;\n' +
  '}\n' +
  '\n' +
  '\n' +
  '//Layering particles to imitate 3D view\n' +
  'vec3 layeredParticles(in vec2 uv, in float sizeMod, in float alphaMod, in int layers, in float smoke)\n' +
  '{\n' +
  '    vec3 particles = vec3(0);\n' +
  '    float size = 1.0;\n' +
  '    float alpha = 1.0;\n' +
  '    vec2 offset = vec2(0.0);\n' +
  '    vec2 noiseOffset;\n' +
  '    vec2 bokehUV;\n' +
  '\n' +
  '    for (int i = 0; i < LAYERS_COUNT; i++)\n' +
  '    {\n' +
  '        //Particle noise movement\n' +
  '        noiseOffset = (noise2_2(uv * size * 2.0 + 0.5) - 0.5) * 0.15;\n' +
  '\n' +
  '        //UV with applied movement\n' +
  '        bokehUV = (uv * size + uTime * MOVEMENT_DIRECTION * MOVEMENT_SPEED) + offset + noiseOffset;\n' +
  '\n' +
  '        //Adding particles\t\t\t\t\t\t\t\tif there is more smoke, remove smaller particles\n' +
  '        particles += fireParticles(bokehUV, uv) * alpha * (1.0 - smoothstep(0.0, 1.0, smoke) * (float(i) / float(layers)));\n' +
  '\n' +
  '        //Moving uv origin to avoid generating the same particles\n' +
  '        offset += hash2_2(vec2(alpha, alpha)) * 10.0;\n' +
  '\n' +
  '        alpha *= alphaMod;\n' +
  '        size *= sizeMod;\n' +
  '    }\n' +
  '\n' +
  '    return particles;\n' +
  '}\n' +
  '\n' +
  'void mainImage( out vec4 fragColor, in vec2 fragCoord )\n' +
  '{\n' +
  '    // Calculate the aspect ratio of the screen\n' +
  '    float aspectRatio = uResolution.x / uResolution.y;\n' +
  '\n' +
  '    // Calculate UV coordinates based on screen aspect ratio\n' +
  '    vec2 uv;\n' +
  '    if (aspectRatio > 1.0) {\n' +
  '        // Landscape orientation or square screen\n' +
  '        uv = (2.0 * fragCoord - uResolution.xy) / uResolution.x;\n' +
  '    } else {\n' +
  '        // Portrait orientation\n' +
  '        uv = (2.0 * fragCoord - uResolution.y * vec2(1.0, aspectRatio)) / uResolution.y;\n' +
  '    }\n' +
  '\n' +
  '    float vignette = 1.0 - smoothstep(0.4, 1.4, length(uv + vec2(0.0, 0.3)));\n' +
  '\n' +
  '    uv *= 1.8;\n' +
  '\n' +
  '    float smokeIntensity = layeredNoise1_2(uv * 10.0 + uTime * 4.0 * MOVEMENT_DIRECTION * MOVEMENT_SPEED, 1.7, 0.7, 6, 0.2);\n' +
  '    smokeIntensity *= pow(1.0 - smoothstep(-1.0, 1.6, uv.y), 2.0);\n' +
  '    vec3 smoke = smokeIntensity * getSmokeColor() * 0.8 * vignette;\n' +
  '\n' +
  '    //Cutting holes in smoke\n' +
  '    smoke *= pow(layeredNoise1_2(uv * 4.0 + uTime * 0.5 * MOVEMENT_DIRECTION * MOVEMENT_SPEED, 1.8, 0.5, 3, 0.2), 2.0) * 1.5;\n' +
  '\n' +
  '    vec3 particles = layeredParticles(uv, SIZE_MOD, ALPHA_MOD, LAYERS_COUNT, smokeIntensity);\n' +
  '\n' +
  '    vec3 col = particles + smoke + getSmokeColor() * 0.02;\n' +
  '    col *= vignette;\n' +
  '\n' +
  '    col = smoothstep(-0.08, 1.0, col);\n' +
  '    col = getThemeColor(col);\n' +
  '\n' +
  '    fragColor = vec4(col, 1.0);\n' +
  '}\n' +
  '\n' +
  'void main() {\n' +
  '    vec2 fragCoord = gl_FragCoord.xy;\n' +
  '    vec4 fragColor = vec4(1.0,1.0,1.0,1.0);\n' +
  '\n' +
  '    // Your calculations for fragColor using mainImage function\n' +
  '    mainImage(fragColor, fragCoord);\n' +
  '\n' +
  '    gl_FragColor = fragColor;\n' +
  '}';
