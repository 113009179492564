import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { appRoutes } from './route/AppRoutes';

import './stylesheet/app.scss';
import { ThemeContextProvider } from './provider/ThemeProvider';
import { THEME_DARK } from './stylesheet/theme';

const App = () => {
  const router = createBrowserRouter(createRoutesFromElements(appRoutes()));
  const themeConfig = THEME_DARK;

  return (
    <ThemeContextProvider config={themeConfig}>
      <div
        style={{
          background: `${themeConfig?.primary?.background ?? 'white'}`,
          width: '100%',
          height: '100%'
        }}
      >
        <RouterProvider router={router} />
      </div>
    </ThemeContextProvider>
  );
};

export default App;
